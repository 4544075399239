import styled from "styled-components";

import { useContext } from "react";
import { ModeContext } from '../utils/contexts/DrkMode';

import logo from "../assets/Logo.svg";
import logoLight from "../assets/Logo-Light.svg";

const LogoWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 36px;
    font-family: "Bebas Neue", sans-serif;
    text-align: center;
    position: relative;
`


export default function LogoNom(){
    const { NightMode } = useContext(ModeContext);
    return(
        <a href="https://www.pierre-rozet.fr">
        <LogoWrapper>
            <img src={NightMode ? logoLight : logo} alt="" style={{height: "50px"}}/> Pierre ROZET
        </LogoWrapper>
        </a>
    )
}
