import React from "react";

export const ModeContext = React.createContext()


export const DrkModeProvider = ({ children }) => {
    const [NightMode, setNightMode] = React.useState(false);
    const handleModeToggle = () => {
        setNightMode(!NightMode);
    };
    
    return (
        <ModeContext.Provider value={{ NightMode, handleModeToggle }}>
            {children}
        </ModeContext.Provider>
    )
}