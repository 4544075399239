import styled from "styled-components";
import React from 'react';
import colors from "../../utils/styles/color";

import { useContext, useState } from "react";
import { ModeContext } from "../../utils/contexts/DrkMode";
import { MobileContext } from "../../utils/contexts/MobileVersion";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

import ImageService from "../../assets/ImageServices";
import ServiceData from "../../assets/ServicesData.json"

import CtaBtn from "../CtaBtn";

const StyledSection = styled.section`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 100px;
    ${(props) => props.isMobile ? "height: 100vh;" : "max-height: 550px;"}
    position: relative;
    &:before {
        content: "Mes Services";
        font-family: "Bebas Neue";
        position: absolute;
        top: 0;
        right: 50%;
        z-index: 6;
        font-size: 36px;
        transform: translate(50%, -50%);
        color: ${(props) => props.nightMode ? "#fff" : colors.tertiary};
        -webkit-text-stroke: 1px black;
    }
`;

const StyledContainer = styled.div`
    position: absolute;
    width: 100%;
    ${(props) => props.isMobile ? "" : `transform: skew(${props.serviceNumber%2 === 0 ? "-25deg" : "25deg"}) translateX(-30%)`};
    transition: transform 1s ease-in-out;
    background-color: ${(props) => props.nightMode ? "white" : colors.primary};
    top: 0;
    bottom: 0;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.6);
`

const PhotoWrapper = styled.div`
    flex: 1;
    min-height: 550px;
    position: relative;
    z-index: -1;
    background-image: url(${(props) => ImageService[props.serviceNumber]});
    background-size: cover;
    ${(props) => props.isMobile ? "display: none;" : ""}
`
const TextWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 30px;
    flex-direction: column;
    flex: 1;
    position: relative;
    padding: ${(props) => props.isMobile ? "15px" : "40px 0px 0px 80px"};;
    color: ${(props) => props.nightMode ? "black" : "white"};
    &p{
        font-size: clamp(0.875rem, -0.0455rem + 1.6364vw, 2rem);
    }
`
const ButtonWrapper = styled.div`
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 250px;
    height: 50px;
    z-index: 5;  
    ${(props) => props.isMobile ? "bottom: 25px; right:50%; transform: translateX(50%);" : "bottom: 25px; right: 150px;"}
`
const ButtonLeftRight = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    background-color: white;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    font-size: 25px;
    cursor: pointer;
`

export default function Services(){
    const { NightMode } = useContext(ModeContext);
    const { isMobile } = useContext(MobileContext);
    const [serviceNumber, setServiceNumber] = useState(1);
    function incraseServiceNumber(){
        if(serviceNumber < 4){
            setServiceNumber(serviceNumber + 1);
        }
    }
    function decraseServiceNumber(){
        if(serviceNumber > 1){
            setServiceNumber(serviceNumber - 1);
        }
    }
    return(
        <StyledSection nightMode={NightMode} isMobile={isMobile}>
            <StyledContainer nightMode={NightMode} serviceNumber={serviceNumber} isMobile={isMobile}/>
            <TextWrapper nightMode={NightMode} isMobile={isMobile}>
                <h3>
                    {ServiceData[serviceNumber].title}
                </h3>
                <p>
                    {ServiceData[serviceNumber].content}
                </p>
                <Link to={`/services/${serviceNumber}`} target="_blank"><CtaBtn color={NightMode ? "black" : colors.tertiary} text="En savoir plus" style={NightMode ? {color: "white"} : {}}/></Link>
            </TextWrapper>
            <PhotoWrapper serviceNumber={serviceNumber} isMobile={isMobile}>
            </PhotoWrapper>
            <ButtonWrapper isMobile={isMobile}>
                <ButtonLeftRight onClick={decraseServiceNumber} style={serviceNumber === 1 ? {opacity: "0.5"} : {opacity: "1"}}><FontAwesomeIcon icon={faArrowLeft} /></ButtonLeftRight>
                <ButtonLeftRight onClick={incraseServiceNumber} style={serviceNumber === 4 ? {opacity: "0.5"} : {opacity: "1"}}><FontAwesomeIcon icon={faArrowRight} /></ButtonLeftRight>
            </ButtonWrapper>
        </StyledSection>
    )
}
