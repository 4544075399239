import salle from "./salle.jpg";
import asperges from "./asperge.jpg";
import vin from "./vin.jpg";

const image = {
    1 : asperges,
    2 : salle,
    3 : vin
}

export default image