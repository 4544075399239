import image1 from "./1.avif";
import image2 from "./2.jpg";
import image3 from "./3.jpg";
import image4 from "./4.jpg"

const ImageService = {
    1: image1,
    2: image2,
    3: image3,
    4: image4
}

export default ImageService