

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import GlobalStyle from './utils/styles/GlobalStyle';


import { DrkModeProvider } from './utils/contexts/DrkMode';
import { MobileVersionProvider } from './utils/contexts/MobileVersion';

import DrkModeBtn from './components/DrkModeBtn';
import Header from './components/Header';
import Footer from './components/Footer';

import Home from './pages/Home';
import Error from './pages/Error';
import Services from './pages/Services';



function App() {
  return (
    <Router>
      <DrkModeProvider>
      <MobileVersionProvider>
          <div className="App">
            <GlobalStyle />
            <DrkModeBtn />
            <Header />
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='*' element= {<Error />} />
              <Route path='/services/:id' element={<Services />}/>
            </Routes>
            <Footer />
          </div>
      </MobileVersionProvider>
      </DrkModeProvider>
    </Router>
  );
}

export default App;
