import styled from "styled-components";
import colors from "../../utils/styles/color";
import image from "../../assets/ImageNews";

import menu from "../../assets/Menu-Printemps.pdf";
import vin from "../../assets/carte-vins.pdf";
import info from "../../assets/info-salle.pdf";


import { useContext } from "react";
import { ModeContext } from "../../utils/contexts/DrkMode";
import { MobileContext } from "../../utils/contexts/MobileVersion";

import CtaBtn from "../CtaBtn";

const StyledSection = styled.section`
    display: flex;
    justify-content: center;
    gap: 25px;
    align-items: center;
    margin-top: 100px;
    position: relative;
    ${(props) => props.isMobile ? "flex-direction: column;" : ""}
    &:before {
        content: "L'actualité";
        font-family: "Bebas Neue";
        position: absolute;
        top: 0;
        right: 50%;
        z-index: 6;
        font-size: 36px;
        transform: translate(50%, -50%);
        color: ${(props) => props.nightMode ? "#fff" : colors.primary};
        -webkit-text-stroke: 1px black;
    }
`;

const StyledNews = styled.div`
    margin-top: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 450px;
    width: 300px;
    overflow: hidden;
    position: relative;
    border-radius: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border: 1px solid ${colors.tertiary};
    transition: all 0.3s ease-in-out;
    &:hover{
        transform: scale(1.2);
    }
`
const StyledContainer = styled.div`
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    background-color: ${(props) => props.nightMode ? "white" : colors.primary};
    ${(props) => props.isMobile ? "display: none;" : ""}
    transform: skew(25deg) translateX(-30%);
    z-index: -1;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.6);
`

const ImgWrapper = styled.div`
    width: 100%;
    flex: 1;
    background-image: url(${(props) => image[props.id]});
    background-size: cover;
    position: relative;
    z-index: -4;
`

const TextWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    flex: 1;
    color: ${(props) => props.nightMode ? "black" : "white"};
    background-color: ${(props) => props.nightMode ? "white" : colors.primary};
    padding: 10px;
`


export default function News(){
    const { NightMode } = useContext(ModeContext);
    const { isMobile } = useContext(MobileContext);
    return(
        <StyledSection nightMode={NightMode} isMobile= {isMobile}>
            <StyledNews nightMode={NightMode}>
                <ImgWrapper id={1}>
                </ImgWrapper>
                <TextWrapper nightMode={NightMode}>
                    <h3>
                        Menu de printemps
                    </h3>
                    <p style={{fontSize : "16px"}}>
                        Notre tout nouveau menu de printemps est arrivé, reservé vite vos seminaires et tables privées.
                    </p>
                    <a href={menu} target="_blank"><CtaBtn color={NightMode ? "black" : colors.tertiary} text="Voir ici" style={NightMode ? {color: "white", fontSize: "20px", minWidth: "120px"} : {fontSize: "20px", minWidth: "120px"}}/></a>
                </TextWrapper>
            </StyledNews>
            <StyledNews nightMode={NightMode}>
                <ImgWrapper id={2}>
                </ImgWrapper>
                <TextWrapper nightMode={NightMode}>
                    <h3>
                        Nouvelle Salle
                    </h3>
                    <p style={{fontSize : "16px"}}>
                        Profitez de notre nouvelle salle, une sublime cave rénnové en plein centre ville de Villefranche s/ Saone.
                    </p>
                    <a href={info} target="_blank"><CtaBtn color={NightMode ? "black" : colors.tertiary} text="Voir ici" style={NightMode ? {color: "white", fontSize: "20px", minWidth: "120px"} : {fontSize: "20px", minWidth: "120px"}}/></a>
                </TextWrapper>
            </StyledNews>
            <StyledNews nightMode={NightMode}>
                <ImgWrapper id={3}>
                </ImgWrapper>
                <TextWrapper nightMode={NightMode}>
                    <h3>
                        Cave a vin
                    </h3>
                    <p style={{fontSize : "16px"}}>
                        Notre Cave s'ettoffe grace à notre partenaire de la cave d'Azé, decouvrez le cuvé Jules Richard, fondateur de la cave.
                    </p>
                    <a href={vin} target="_blank"><CtaBtn color={NightMode ? "black" : colors.tertiary} text="Carte des vins" style={NightMode ? {color: "white", fontSize: "20px", minWidth: "120px"} : {fontSize: "20px", minWidth: "120px"}}/></a>
                </TextWrapper>
            </StyledNews>
        </StyledSection>
    )
}
