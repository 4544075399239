import React from 'react';
import styled from "styled-components";
import colors from "../utils/styles/color";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoon } from '@fortawesome/free-solid-svg-icons';
import { faSun } from "@fortawesome/free-solid-svg-icons";
import { useContext } from "react";

import { ModeContext } from '../utils/contexts/DrkMode';


const StyledButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 20px;
    right: 10px;
    height: 50px;
    width: 50px;
    background: ${(props) => props.darkMode ? colors.secondary : colors.primary};
    border-radius: 50%;
    cursor: pointer;
    font-size: 25px;
    z-index: 6;
`

function DrkModeBtn() {
    const { NightMode, handleModeToggle } = useContext(ModeContext);
    
    return <StyledButton onClick={handleModeToggle} darkMode={NightMode}>
        { NightMode ? (<FontAwesomeIcon style={{ color: 'black'}} icon={faSun} />) : (<FontAwesomeIcon style={{ color: 'white'}} icon={faMoon} />)
        }
        </StyledButton>
}

export default DrkModeBtn