import { createGlobalStyle } from 'styled-components';
import colors from "./color";

import { useContext } from "react";
import { ModeContext } from '../contexts/DrkMode';

const StyledGlobalStyle = createGlobalStyle`
    html{
        height: 100%;
        line-height: 1.15; /* 1 */
        -webkit-text-size-adjust: 100%; 
    }
    .App-header {
        background-color: ${ (props) => props.mode ? colors.primary : "white"}
    }
    *{
        font-family: 'Nunito Sans', sans-serif;
        box-sizing: border-box;
        text-decoration: none;
        color: inherit
    }
    ol, ul, li {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    body{
        position: relative;
        height: 100%;
        margin: 0;
        padding: 0;
        background-color: ${(props) => props.mode ? "black" : "white"};
        overflow-x: hidden;
    }
    h3{
        font-family: "Bebas Neue", sans-serif;
        font-size: 36px;
        margin: 0;
    }
    p, li{
        font-family: "Concert One", sans-serif;
        font-size: 22px;
        text-align: justify;
    }
`

function GlobalStyle() {
    const { NightMode } = useContext(ModeContext);
    return <StyledGlobalStyle mode={NightMode}/>
}

export default GlobalStyle