import styled from "styled-components";
import colors from "../../utils/styles/color";

import { useContext } from "react";
import { ModeContext } from "../../utils/contexts/DrkMode";
import { MobileContext } from "../../utils/contexts/MobileVersion";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons";
import { faInstagramSquare } from "@fortawesome/free-brands-svg-icons";



const StyledSection = styled.section`
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 100px;
    color: ${(props) => props.nightMode ? "white" : colors.primary};
    ${(props) => props.isMobile ? "height: 100vh; flex-direction: column;" : ""}
    position: relative;
    padding-top: 50px;
    margin-bottom: 50px;
    font-size: 150px;
    &:before {
        content: "Mes Reseaux";
        font-family: "Bebas Neue";
        position: absolute;
        top: 0;
        right: 50%;
        z-index: 6;
        font-size: 36px;
        transform: translate(50%, -50%);
        color: ${(props) => props.nightMode ? "#fff" : colors.primary};
    }
`;

export default function SocialNetwork(){
    const { NightMode } = useContext(ModeContext);
    const { isMobile } = useContext(MobileContext);
    return(
        <StyledSection nightMode={NightMode} isMobile={isMobile}>
            <a href="https://www.linkedin.com/in/pierre-rozet/" target="_blank"><FontAwesomeIcon icon={faLinkedin} /></a>
            <a href="https://www.facebook.com/profile.php?id=61557517861317" target="_blank"><FontAwesomeIcon icon={faFacebookSquare} /></a>
            <a href="https://www.instagram.com/bypierrerozet?igsh=MTZhczhzaDFhZDBhZg%3D%3D&utm_source=qr" target="_blank"><FontAwesomeIcon icon={faInstagramSquare} /></a>
        </StyledSection>
    )
}
