import styled from "styled-components"

const StyledBtn = styled.div`
    padding: 20px;
    display: inline-block;
    text-align: center;
    border-radius: ${({ radius }) => radius ? radius : "20px"};
    min-width: 200px;
    font-size: 30px;
    font-family: "Bebas Neue", sans-serif;
    color: ${({ font }) => font ? font : "#000"};
    background-color: ${({ color }) => color ? color : "#fff"};
    cursor: pointer;
`

export default function CtaBtn(props) {
    return(
        <StyledBtn color={props.color} font={props.font} radius={props.radius} style={props.style}>{props.text}</StyledBtn>
    )
}