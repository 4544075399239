import styled from "styled-components";
import colors from "../../utils/styles/color";

import { useContext } from "react";
import { ModeContext } from "../../utils/contexts/DrkMode";
import { MobileContext } from "../../utils/contexts/MobileVersion";

import photo from "../../assets/IMG_2879.JPG";
import photoManga from "../../assets/histoire-manga.jpeg"
import cv from "../../assets/Pierre-1.pdf"
import CtaBtn from "../CtaBtn";

const StyledSection = styled.section`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 100px;
    position: relative;
    overflow-x: clip;
    ${(props) => props.isMobile ? "height: 100vh;" : "max-height: 550px;"}
    &:before {
        content: "Mon Histoire";
        font-family: "Bebas Neue";
        position: absolute;
        top: 0;
        right: 50%;
        z-index: 6;
        font-size: 36px;
        transform: translate(50%, -50%);
        color: ${(props) => props.nightMode ? "#fff" : colors.tertiary};
        -webkit-text-stroke: 1px black;
    }
`;

const StyledContainer = styled.div`
    position: absolute;
    width: 100%;
    ${(props) => props.isMobile ? "" : "transform: skew(25deg) translateX(30%)"};
    background-color: ${(props) => props.nightMode ? "white" : colors.primary};
    top: 0;
    bottom: 0;
    box-shadow: -4px 0px 10px rgba(0, 0, 0, 0.6);
`;

const PhotoWrapper = styled.div`
    flex: 1;
    min-height: 550px;
    position: relative;
    z-index: -1;
    background-image: url(${(props) => props.nightMode ? photoManga : photo});
    background-size: cover;
    ${(props) => props.isMobile ? "display: none;" : ""}
`
const TextWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: ${(props) => props.isMobile ? "center" : "flex-end"};
    gap: 30px;
    flex-direction: column;
    flex: 1;
    position: relative;
    padding: ${(props) => props.isMobile ? "15px" : "40px 80px 0px 0px"};
    color: ${(props) => props.nightMode ? "black" : "white"};
    &p{
        font-size: clamp(0.875rem, -0.0455rem + 1.6364vw, 2rem);
    }
`

export default function History(){
    const { NightMode } = useContext(ModeContext);
    const { isMobile } = useContext(MobileContext);
    return(
        <StyledSection nightMode={NightMode} isMobile={isMobile}>
            <StyledContainer nightMode={NightMode} isMobile={isMobile}/>
            <PhotoWrapper nightMode={NightMode} isMobile={isMobile}/>
            <TextWrapper nightMode={NightMode} isMobile={isMobile}>
                <h3>
                    Artisan de bouche
                </h3>
                <p>
                D’apprenti pâtissier à chef de cuisine, d’une chocolaterie genevoise à un hôtel du beaujolais mon parcours m’a permis d’explorer les différentes facettes du monde culinaire.
                </p>
                <a href={cv} target="_blank"><CtaBtn color={NightMode ? "black" : colors.tertiary} text="Mon CV" style={NightMode ? {color: "white"} : {}}/></a>
            </TextWrapper>
        </StyledSection>
    )
}
