import styled from "styled-components";
import colors from "../../utils/styles/color";

import { useContext } from "react";
import { ModeContext } from "../../utils/contexts/DrkMode";
import { MobileContext } from "../../utils/contexts/MobileVersion";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneSquare } from "@fortawesome/free-solid-svg-icons";
import { faAt } from "@fortawesome/free-solid-svg-icons";

import photo from "../../assets/cathedrale.jpg"

const StyledSection = styled.section`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 100px;
    ${(props) => props.isMobile ? "height: 100vh;" : "max-height: 550px;"}
    position: relative;
    &:before {
        content: "Contact";
        font-family: "Bebas Neue";
        position: absolute;
        top: 0;
        right: 50%;
        z-index: 6;
        font-size: 36px;
        transform: translate(50%, -50%);
        color: ${(props) => props.nightMode ? "#fff" : colors.tertiary};
        -webkit-text-stroke: 1px black;
    }
`;

const StyledContainer = styled.div`
    position: absolute;
    width: 100%;
    ${(props) => props.isMobile ? "" : "transform: skew(25deg) translateX(-30%)"};
    background-color: ${(props) => props.nightMode ? "white" : colors.primary};
    top: 0;
    bottom: 0;
`;

const PhotoWrapper = styled.div`
    flex: 1;
    min-height: 550px;
    position: relative;
    z-index: -1;
    background-image: url(${photo});
    background-size: cover;
    ${(props) => props.isMobile ? "display: none;" : ""}
`
const TextWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: ${(props) => props.isMobile ? "center" : "flex-start"};
    gap: 30px;
    flex-direction: column;
    flex: 1;
    position: relative;
    padding: ${(props) => props.isMobile ? "30px" : "40px 0px 0px 80px"};
    box-sizing: border-box;
    color: ${(props) => props.nightMode ? "black" : "white"};
    &p{
        font-size: clamp(0.875rem, -0.0455rem + 1.6364vw, 2rem);
    }
`

export default function ContactSpace(){
    const { NightMode } = useContext(ModeContext);
    const { isMobile } = useContext(MobileContext);
    return(
        <StyledSection nightMode={NightMode} id="contact" isMobile={isMobile}>
            <StyledContainer nightMode={NightMode} isMobile={isMobile}/>
            <TextWrapper nightMode={NightMode} isMobile={isMobile}>
                <h3>
                    Rencontrons-nous !
                </h3>
                <p>
                    Je suis disponible dans toute la Calade, le Beaujolais & la region Lyonnaise. Discutons ensemble de votre projet et de comment le realiser :
                </p>
                <ul>
                    <a href="mailto:pierre.rozet@gmail.com"><li><FontAwesomeIcon icon={faAt}/> Pierre.rozet@gmail.com</li></a>
                    <a href="tel:0033768068034"><li><FontAwesomeIcon icon={faPhoneSquare}/> 07.68.06.80.34</li></a>
                </ul>
            </TextWrapper>
            <PhotoWrapper isMobile={isMobile}/>
        </StyledSection>
    )
}