import styled from "styled-components";
import colors from "../utils/styles/color";

import { useContext } from "react";
import { ModeContext } from '../utils/contexts/DrkMode';

const StyledFooter = styled.footer`
    height: 90px;
    background-color: ${(props) => props.nightMode ? "white" : colors.primary};
    color: ${(props) => props.nightMode ? "black" : "white"};
    font-family: "Bebas Neue", sans serif;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    & a{
        font-family: inherit;
    }
`

export default function Footer(){
    const { NightMode } = useContext(ModeContext);
    return(
      <StyledFooter nightMode={NightMode}>
            Site réalisé par <a href="https://github.com/Baked-Code">Baked-Code</a>, tous droits reservés.      
      </StyledFooter>
    )
}