import React from "react";

export const MobileContext = React.createContext()


export const MobileVersionProvider = ({ children }) => {
    const [windowWidth, setWindowSize] = React.useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWindowSize(window.innerWidth);
    };

    React.useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    let isMobile = windowWidth <= 900;
    
    return (
        <MobileContext.Provider value={{ windowWidth, handleWindowSizeChange, isMobile }}>
            {children}
        </MobileContext.Provider>
    )
}