import styled from "styled-components";
import colors from "../utils/styles/color";
import CtaBtn from "./CtaBtn";
import LogoNom from "./LogoNom";

import { useContext } from "react";
import { ModeContext } from '../utils/contexts/DrkMode';
import { MobileContext } from "../utils/contexts/MobileVersion";

import { Link } from "react-router-dom";

const StyledLink = styled(Link)`
    color: inherit;
    font-size: inherit;
    font-family: inherit;
`

const StyledNav = styled.nav`
    background-color: ${(props) => props.nightMode ? "#fff" : colors.primary};
    position: relative;
    height: ${(props) => props.isMobile ? "100vh" : "140px"};
    color: ${(props) => props.nightMode ? "#000" : "#fff"};
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "Bebas Neue", sans-serif;
    font-size: clamp(1.25rem, 0.5833rem + 1.3333vw, 2.25rem);
    ${(props) => props.isMobile ? "flex-direction: column;" : ""}
`

export default function Header() {
    const { NightMode } = useContext(ModeContext);
    const { isMobile } = useContext(MobileContext);
    return(
        <StyledNav nightMode={NightMode} isMobile={isMobile}>
            <CtaBtn font={NightMode ? "white" : "black"} color={NightMode ? "black" : "white"} radius="0px 0px 20px 0px" text={<LogoNom></LogoNom>} style={{marginBottom: "70px"}} />
            <StyledLink to="services/1">Table privé</StyledLink> · <StyledLink to="services/2">Cours de Cuisine</StyledLink> · <StyledLink to="services/3">Formation Professionelle</StyledLink> · <StyledLink to="services/4">Cooking Show</StyledLink>
            <a href="/#contact"><CtaBtn font={NightMode ? "white" : "black"} color={NightMode ? "black" : "white"} radius="20px 0px 0px 0px" text="Contactez-Moi" style={{marginTop: "70px"}}/></a>
        </StyledNav>
    )
}