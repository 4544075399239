import styled from "styled-components";
import colors from "../../utils/styles/color";

import { useContext } from "react";
import { ModeContext } from "../../utils/contexts/DrkMode";
import { MobileContext } from "../../utils/contexts/MobileVersion";

import profil from "../../assets/profil.png";
import profilManga from "../../assets/profil-manga.png"



const StyledSection = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    color: ${(props) => props.nightMode ? "white" : "black"};
    ${(props) => props.isMobile ? "height: 100vh; flex-direction: column" : ""}
`;

const PhotoWrapper = styled.div`
    height: 250px;
    position: relative;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    &:after {
        content: "";
        position: absolute;
        width: 180px;
        height: 180px;
        background-color: ${colors.primary};
        border-radius: 50%;
        z-index: -2;
        bottom: 0;
        right: 0;
        transform: translateX(-50%);
    }
    &:before {
        content: "";
        position: absolute;
        width: 180px;
        height: 180px;
        background-color: ${colors.tertiary};
        border-radius: 50%;
        z-index: -2;
        top: 0;
        left: 0;
        transform: translateX(50%);
    }
`;

const TextWrapper = styled.div`
    flex: 2;
    font-family: "Concert One", sans-serif;
    font-size: 32px;
    padding: 40px;
    ${(props) => props.isMobile ? "text-align: center;" : "margin-right: 30px;"}
`;

export default function HeroBanner(){
    const { NightMode } = useContext(ModeContext);
    const { isMobile } = useContext(MobileContext);
    return(
        <StyledSection nightMode={NightMode} isMobile={isMobile}>
            <PhotoWrapper>
                <img src={NightMode ? profilManga : profil} alt="" style={{height: "250px"}}/>
            </PhotoWrapper>
            <TextWrapper isMobile={isMobile}>
            Explorez, goûtez, créez. Votre voyage culinaire commence ici. Tables privées, repas conviviaux, cours qui inspirent, plongez dans l'univers de Pierre Rozet ; où chaque plat raconte son histoire.
            </TextWrapper>
        </StyledSection>
    )
}
