import styled from "styled-components";
import colors from "../utils/styles/color";

import { useContext, useState, useEffect } from "react";
import { ModeContext } from "../utils/contexts/DrkMode";
import { MobileContext } from "../utils/contexts/MobileVersion";

import { useParams } from "react-router-dom";

import datafile from "../assets/DataServices.json";
import CtaBtn from "../components/CtaBtn";


const StyledGrid = styled.div`
    display: grid;
    margin-top: 100px;
    padding-bottom: 100px;
    gap: 150px;
    grid-template-rows: repeat(auto-fill, 550px);
    grid-template-columns: repeat(auto-fill, ${(props) => props.isMobile ? "90%" : "450px"});
    justify-content: center;
`;

const StyledCard = styled.div`
    overflow: hidden;
    background: ${(props) => props.nightMode ? "white" : colors.primary};
    ${(props) => props.isMobile ? "Width: 100%;" : "width: 450px;"};
    height: 550px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 20px;
    position: relative;
    flex-direction: column;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.6);
    color: ${(props) => props.nightMode ? "black" : "white"};
`

const TextWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 2;
    padding: 10px;
`

const ImgWrapper = styled.div`
    background-image: url(${(props) => props.url});
    background-size: cover;
    height: 100%;
    width: 100%;
    flex: 1;
    position: relative;

`

export default function Services(){
    const { id } = useParams();
    const { NightMode } = useContext(ModeContext);
    const { isMobile } = useContext(MobileContext);
    const [data, setData] = useState([]);
    useEffect(() => {
        async function fetchData(){
            const filtredData = datafile.filter(item => item.categorie === parseInt(id))
            setData(filtredData); 
        }
    fetchData()}, [id]);
    return (
        <StyledGrid isMobile={isMobile}>
            {data.length > 0 ? (
        data.map(item => (
            <StyledCard nightMode={NightMode} key={item.titre} isMobile={isMobile}>
                <ImgWrapper url={`/images/${item.image}`}/>
                <TextWrapper>
                    <h3>{item.titre}</h3>
                    <p>
                        {item.description}
                    </p>
                    {item.prix && <a href="mailto:pierre.rozet@gmail.com"><CtaBtn color={NightMode ? "black" : colors.tertiary} text={item.prix} style={NightMode ? {color: "white"} : {}}/></a>}
                </TextWrapper>
            </StyledCard>
        ))
    ) : (
        <StyledCard nightMode={NightMode}>
            <ImgWrapper />
            <TextWrapper>
                erreur de chargement
            </TextWrapper>
        </StyledCard>
    )}
        </StyledGrid>
        
    )
}
