import HeroBanner from '../components/sections/HeroBanner';
import Services from '../components/sections/ServicesCarousel';
import SocialNetwork from '../components/sections/SocialNetwork';
import History from '../components/sections/History';
import News from '../components/sections/News';
import ContactSpace from '../components/sections/ContactSpace';

export default function Home() {
    return(
      <main>
        <HeroBanner />
        <Services />
        <SocialNetwork />
        <History />
        <News />
        <ContactSpace />
      </main>
    )
}